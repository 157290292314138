import * as React from "react"
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Head from '../components/Head'

const IndexPage = ({ data }) => {
    const header1 = data.header1

    return (
        <Layout>
            <Head title="Home" />
            <div className="container content">
                <section>
                    <div className="row mt-3 align-items-center">
                        <div className="col">
                            <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 align-middle animate__animated animate__fadeIn">
                                <h1 className="p-3 emphasis-text ">
                                    We deliver the best <span
                                        className="brand-color border-bottom border-info">automation</span> solutions for your
                                    industry.
                                </h1>
                                <div className="text-muted fw-light p-2 me-2 ms-2 mb-2 " style={{ fontSize: "1.1rem" }}>Work with the
                                    <span className="brand-color"> best</span>. Work with <span className="brand-color">us</span>.
                                </div>
                            </div>
                            <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 animate__animated animate__fadeIn">
                                <div className="ps-3 pe-3 d-flex">
                                    {/* <Link to="/contact-us" className="btn primary-btn w-50 me-2"><span
                                        className="fw-bold">Catalog</span></Link> */}
                                    <Link to="/contact-us" className="btn secondary-btn w-50 me-2"><span
                                        className="fw-bold">Enquire</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="mt-4 me-2 ms-2 mb-2 text-white p-2">
                                <div className="p-3">
                                    <GatsbyImage image={getImage(header1)} alt="Banner" className="shadow-lg rounded"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <section>
                <div className="row mt-3 align-items-center">
                    <div className="col">
                        <div className="row text-white">
                            <div className="col-lg-6 bg-dark p-4 animate__animated animate__fadeIn">
                                <h4 className="brand-color fw-light border-bottom border-info lh-lg">Design. <i
                                    className="bi bi-kanban"></i></h4>
                                <small className="text-muted lh-lg">
                                    We <span className="brand-color">design and develop</span> systems according to your
                                    industrial needs. Provide us with your requirements and the rest is <span
                                        className="brand-color">upto us</span>.
                                </small>
                            </div>
                            <div className="col-lg-6 bg-dark p-4">
                                <h4 className="brand-color fw-light border-bottom border-info lh-lg">Products. <i
                                    className="bi bi-box-seam"></i></h4>
                                <small className="text-muted lh-lg">
                                    We deal in exceptional <span className="brand-color">products</span> across a wide
                                    range.
                                    Contact us with your requirements and we will get the product for you.
                                </small>
                            </div>
                            <div className="col-lg-6 bg-dark p-4">
                                <h4 className="brand-color fw-light border-bottom border-info lh-lg">Quality. <i
                                    className="bi bi-award"></i></h4>
                                <small className="text-muted lh-lg">
                                    We have a very heavy emphasis on <span className="brand-color">quality.</span>
                                    Therefore,
                                    you can be completely assured that the solution provided meets <span
                                        className="brand-color">highest standards</span>.
                                </small>
                            </div>
                            <div className="col-lg-6 bg-dark p-4">
                                <h4 className="brand-color fw-light border-bottom border-info lh-lg">Assurance. <i
                                    className="bi bi-shield-check"></i></h4>
                                <small className="text-muted lh-lg">
                                    Our solutions come with an <span className="brand-color">assurance</span> of working
                                    100%
                                    so that you don't face any problems at your end.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 animate__animated animate__fadeIn">
                            <h1 className="p-3 emphasis-text">
                                Solutions devised just according to <span
                                    className="brand-color border-bottom border-info">your needs</span>.
                            </h1>
                            <div className="text-muted fw-light p-2 me-2 ms-2 mb-2 " style={{ fontSize: "1.1rem" }}>Best
                                solutions, <span className="brand-color">delivered</span>.</div>
                        </div>
                        <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 animate__animated animate__fadeIn">
                            <div className="ps-3 pe-3 d-flex">
                                <Link to="/services" className="btn secondary-btn w-50 me-2"><span
                                    className="fw-bold">Services</span></Link>
                                <Link to="/products" className="btn primary-btn w-50 me-2"><span
                                    className="fw-bold">Products</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="mt-3">
                    <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 text-center animate__animated animate__fadeIn">
                        <h1 className="p-3 emphasis-text">
                            <span className="brand-color border-bottom border-info">Services</span> that bring value to
                            your industrial needs.
                        </h1>
                        <div className="text-muted fw-light p-2 me-2 ms-2 mb-2 " style={{ fontSize: "1.1rem" }}>
                            We extend services that help industries solve problems quickly and effectively.
                        </div>
                    </div>
                    <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 animate__animated animate__fadeIn">
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            <div className="col">
                                <div className="card shadow-lg bg-dark">
                                    <div className="card-body">
                                        <h4 className="brand-color fw-light border-bottom border-info lh-lg">SPM Development</h4>
                                        <small className="text-muted lh-lg">We have expertise in designing and developing systems that are specific to requirements.</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card shadow-lg bg-dark">
                                    <div className="card-body">
                                        <h4 className="brand-color fw-light border-bottom border-info lh-lg">Industrial Robotics</h4>
                                        <small className="text-muted lh-lg">We extend our services in the field of robotics to help organizations make their production process fast and efficient.</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card shadow-lg bg-dark">
                                    <div className="card-body">
                                        <h4 className="brand-color fw-light border-bottom border-info lh-lg">Machine Development</h4>
                                        <small className="text-muted lh-lg">Machine designing and developing is a field in which we have huge expertise in. We design and develop machines according to requirements. </small>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card shadow-lg bg-dark">
                                    <div className="card-body">
                                        <h4 className="brand-color fw-light border-bottom border-info lh-lg">Application Development</h4>
                                        <small className="text-muted lh-lg">We can work on wide range of control systems from different brands. We design applications according to the requirements.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="mt-3">
                    <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 text-center animate__animated animate__fadeIn">
                        <h1 className="p-3 emphasis-text">
                            Get the best products delivered to you at your <span
                                className="brand-color border-bottom border-info">facility</span>.
                        </h1>
                        <div className="text-muted fw-light p-2 me-2 ms-2 mb-2 " style={{ fontSize: "1.1rem" }}>
                            We deal in exceptional products to suffice your industrial needs. We have a huge range of
                            products from exceptional brands.
                        </div>
                    </div>
                    <div className="mt-4 me-2 ms-2 mb-2 text-white p-2">
                        <div className="p-3">
                            <div className="row p-3">
                                <div className="col-lg-2 text-muted p-4 rounded shadow border-end border-bottom border-info">
                                    <small>Brands We Deal In</small>
                                </div>
                                <div className="col-lg-10 p-4 dark-contrast">
                                    <div className="row">
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">Autonics</small>
                                        </div>
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">Contrinex</small>
                                        </div>
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">Shihlin</small>
                                        </div>
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">ABB</small>
                                        </div>
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">Rockwell</small>
                                        </div>
                                        <div className="text-muted col-lg-2 text-center">
                                            <small className="brand-color">Panasonic</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center p-2 dark-contrast">
                                    <small className="text-muted">We deal in many more brands. <Link to="/contact-us"
                                        className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="card bg-transparent border-0 shadow-lg p-3" style={{ height: "200px" }}>
                                        <div className="text-center text-muted fw-light fs-1">
                                            <i className="bi bi-patch-check"></i>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text text-justify fw-light text-muted">
                                                Get <span className="brand-color">authentic, original and new </span>
                                                products
                                                always. 100% Guaranteed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card bg-transparent border-0 shadow-lg p-3" style={{ height: "200px" }}>
                                        <div className="text-center text-muted fw-light fs-1">
                                            <i className="bi bi-tag"></i>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text text-justify fw-light text-muted">
                                                Get <span className="brand-color">best prices</span> across all products
                                                always.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card bg-transparent border-0 shadow-lg p-3" style={{ height: "200px" }}>
                                        <div className="text-center text-muted fw-light fs-1">
                                            <i className="bi bi-truck"></i>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text text-justify fw-light text-muted">
                                                Get <span className="brand-color">your orders shipped</span> right to your
                                                facility.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="mt-3">
                    <div className="mt-4 me-2 ms-2 mb-2 text-white p-2 ">
                        <div className="row shadow">
                            <div className="col-lg-8 dark-contrast">
                                <div className="p-3 text-muted">
                                    <form method="POST" name="newsletter" data-netlify="true" data-netlify-honeypot="bot-field">
                                        <input type="hidden" name="form-name" value="newsletter" />
                                        <div className="mb-3">
                                            <label htmlFor="newsletter" className="form-label"><small>Email
                                                address</small></label>
                                            <div className="input-group">
                                                <input type="email" className="form-control border-0 rounded-start" id="newsletter"
                                                    placeholder="name@example.com" aria-describedby="basic-addon1" />
                                                <span className="input-group-text border-dark bg-transparent text-muted" id="basic-addon1"><button className="btn text-muted p-0" type="submit"><small>Submit</small></button></span>
                                            </div>
                                            <div id="newsletterHelp" className="form-text">We'll never share your email with
                                                anyone else.</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex align-items-center">
                                <div className="p-3 text-muted">
                                    <span className="brand-color">Subscribe</span> to our Newsletter !
                                    <div>
                                        <small>Don't worry we won't spam you. We dislike spam as well. You will receive a
                                            mail at the end of every month.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </Layout >
    )
}

export default IndexPage

export const query = graphql`
query Header {
    header1: file(relativePath: { eq: "header.webp" }) {
        childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
            )
        }
    }
}
`
